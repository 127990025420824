export enum SMSReportStatusEnum {
  DELIVERED = "delivered",
  UNDELIVERED = "undelivered",
  SCHEDULED = "scheduled",
  ENROUTE = "enroute",
  REJECTED = "rejected",
  ACCEPTED = "accepted",
  UNKNOWN = "unknown",
  INVALID_NUMBER = "invalid_number",
  PROCESSING = "processing",
  WAITING = "waiting",
  ERROR = "error",
  EXPIRED = "expired",
  FORBIDDEN = "forbidden",
  PROCON = "procon",
  BLACKLIST = "blacklist",
}