import { MenuItem } from "../../Types/Layout/SidebarType";

export const MenuList: MenuItem[] = [
  {
    title: "General",
    Items: [
      {
        title: "contacts",
        id: 0,
        icon: "bookmark",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/contact/blacklist`,
            title: "blacklist",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/contact/procon`,
            title: "procon",
            type: "link",
          },
        ],
      },
      {
        title: "Campaigns",
        id: 1,
        icon: "email",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/campaigns/sms`,
            title: "sms",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/campaigns/sms/reports`,
            title: "reports",
            type: "link",
          },
        ],
      },
      {
        title: "Companies",
        id: 2,
        icon: "user",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/companies`,
            title: "Companies",
            type: "link",
          },
        ],
      },
      // {
      //   title: "Financial",
      //   id: 4,
      //   icon: "layout",
      //   type: "sub",
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/financial/transactions`,
      //       title: "transactions",
      //       type: "link",
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/financial/balance`,
      //       title: "balance",
      //       type: "link",
      //     },
      //     {
      //       path: `${process.env.PUBLIC_URL}/financial/pos-paid`,
      //       title: "pos_paid",
      //       type: "link",
      //     }
      //   ],
      // },
      {
        title: "Configurations",
        id: 4,
        icon: "knowledgebase",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/configurations/services`,
            title: "Services",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/configurations/gateway`,
            title: "gateway",
            type: "link",
          },
        ],
      },
    ],
  },
];

export const MenuListReseller: MenuItem[] = [
  {
    title: "General",
    Items: [
      {
        title: "contacts",
        id: 0,
        icon: "bookmark",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/contact/blacklist`,
            title: "blacklist",
            type: "link",
          },
        ],
      },
      {
        title: "Campaigns",
        id: 1,
        icon: "email",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/campaigns/sms`,
            title: "sms",
            type: "link",
          },
          // {
          //   path: `${process.env.PUBLIC_URL}/campaigns/sms/reports`,
          //   title: "reports",
          //   type: "link",
          // },
        ],
      },
      {
        title: "Companies",
        id: 2,
        icon: "user",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/companies`,
            title: "Companies",
            type: "link",
          },
        ],
      },
      {
        title: "Financial",
        id: 4,
        icon: "layout",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/financial/credits`,
            title: "credits",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/financial/pos-paid`,
            title: "pos_paid",
            type: "link",
          }
        ],
      },
      {
        title: "Configurations",
        id: 4,
        icon: "knowledgebase",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/configurations/services`,
            title: "Services",
            type: "link",
          },
        ],
      },
    ],
  },
];

export const MenuListCustomers: MenuItem[] = [
  {
    title: "General",
    Items: [
      {
        title: "contacts",
        id: 0,
        icon: "bookmark",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/contact/blacklist`,
            title: "blacklist",
            type: "link",
          },
        ],
      },
      {
        title: "Campaigns",
        id: 1,
        icon: "email",
        type: "sub",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/campaigns/sms`,
            title: "sms",
            type: "link",
          },
          // {
          //   path: `${process.env.PUBLIC_URL}/campaigns/sms/reports`,
          //   title: "reports",
          //   type: "link",
          // },
        ],
      },
    ],
  },
];
