export enum CampaignStatusEnum {
  PENDING = 'pending',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PAUSED = 'paused',
  DELETED = 'deleted',
  PROCESSING = 'processing',
  SENT = 'sent',
  SCHEDULED = 'scheduled',
  PREPARING = 'preparing',
  ERROR = 'error',
  FORBIDDEN = 'forbidden',
  INSUFFICIENT_BALANCE = 'insufficient_balance',
}
